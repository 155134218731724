import Api from '@/api/base'
import {
  PRODUCT_INFO_V1, PRODUCT_INFO_V2,
  PRODUCT_CERTIFICATE, PRODUCT_CERTIFICATE_VIEW,
} from '@/api/resource'

export default {
  findByQRCode(data) {
    return Api().get(PRODUCT_INFO_V1, {
      params: {
        s: data,
      },
    })
  },
  findByQRCode2(data) {
    return Api().get(PRODUCT_INFO_V2, {
      params: {
        s: data,
      },
    })
  },

  viewTestCertificate(batchNumber) {
    const url = `${Api().defaults.baseURL}/${PRODUCT_CERTIFICATE_VIEW}?bn=${batchNumber}&dl=1`
    window.open(url)
  },
  getTestCertificate() {
    return Api().get(PRODUCT_CERTIFICATE)
  },
  saveTestCertificate(data) {
    const formData = new FormData()
    for (let index = 0; index < data.length; index += 1) {
      const element = data[index]
      if (element.batchNumber !== '' && element.certificateFile) {
        formData.append('batchNumber', element.batchNumber)
        formData.append('certificateFile', element.certificateFile, element.certificateFile.name)
      }
    }

    return Api().post(PRODUCT_CERTIFICATE, formData)
  },
  deleteTestCertificate(batchNumber) {
    return Api().delete(PRODUCT_CERTIFICATE, {
      data: {
        batchNumber,
      },
    })
  },
}
