<template>
  <v-container>
    <div class="base-container">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Certificate of Authority</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="info"
              dark
              class="mb-2"
              @click="refresh"
            >
              Refresh
            </v-btn>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-dialog
              v-model="dialog"
              max-width="650px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Upload COA
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Upload COA</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row v-for="(item, index) in formItems" :key="index" no-gutters>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                      >
                        <!-- :v-model="`editedItem.batchNumber${index}`" -->
                        <!--
                          Condition for autofocus
                          ref: https://stackoverflow.com/questions/51472947/vuetifys-autofocus-works-only-on-first-modal-open
                        -->
                        <v-text-field
                          v-if="dialog && index === 0"
                          autofocus
                          v-model="formItems[index].batchNumber"
                          label="Batch Number"
                        ></v-text-field>
                        <v-text-field
                          v-if="dialog && index > 0"
                          v-model="formItems[index].batchNumber"
                          label="Batch Number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="9"
                      >
                        <v-file-input
                          show-size
                          truncate-length="15"
                          label="COA File"
                          v-model="formItems[index].certificateFile"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.fileName="{ item }">
          <a href="#" @click="viewCertificate(item)">{{ item.fileName }}</a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-file-upload
          </v-icon>
          <!-- <v-file-input
            hide-input
            prepend-icon="mdi-file-upload"
            v-model="editedFile"
            @click="editItem(item)"
          >
          </v-file-input> -->
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <!-- <v-btn
            color="primary"
            @click="initialize"
          >
            Reset
          </v-btn> -->
        </template>
      </v-data-table>
    </div>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import productApi from '@/api/product';

// const moment = require('moment');
const _ = require('lodash');

export default {
  data: () => ({
    snackbar: false,
    snackbarText: null,
    alertType: 'success',
    alertMsg: 'Genuine Product',
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Batch No.', align: 'start', value: 'batchNumber' },
      { text: 'COA File', value: 'fileName' },
      { text: 'Last Update', value: 'lastUpdate' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    items: [],
    formItems: [
      { batchNumber: '', certificateFile: null },
      { batchNumber: '', certificateFile: null },
      { batchNumber: '', certificateFile: null },
      { batchNumber: '', certificateFile: null },
      { batchNumber: '', certificateFile: null },
    ],
    editedIndex: -1,
    editedItem: null,
  }),

  mounted() {
    console.log('mounted')
  },

  created() {
    // console.log(this.$route.params.s)
    // this.fetchCertificate(this.$route.query.s)
    // const s = this.$route.query.s || this.$route.params.s;

    // for (let index = 0; index < this.numberOfItems; index += 1) {
    //   this.editedItem.push({ ...this.defaultItem })
    // }
    this.fetchCertificate();
  },

  watch: {
    dialog(val) {
      return val || this.close()
    },
    dialogDelete(val) {
      console.log(val)
      // val || this.closeDelete()
    },
  },

  methods: {
    close() {
      // this.dialog = false
      this.$nextTick(() => {
        _.each(this.formItems, (item) => {
          const item2 = item
          item2.batchNumber = ''
          item2.certificateFile = null
        })
        console.log('close')
        this.editedItem = null
        this.editedIndex = -1
      })
    },

    editItem(item) {
      console.log(item)
      this.formItems[0].batchNumber = item.batchNumber
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const item = this.items.splice(this.editedIndex, 1)
      if (item.length > 0) {
        productApi.deleteTestCertificate(item[0].batchNumber)
      }

      this.closeDelete()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = null
        this.editedIndex = -1
      })
    },

    save() {
      // if (this.editedIndex > -1) {
      //   Object.assign(this.desserts[this.editedIndex], this.editedItem)
      // } else {
      //   this.desserts.push(this.editedItem)
      // }
      // this.editedItem.forEach((e) => this.desserts.push(e))
      productApi
        .saveTestCertificate(this.formItems)
        .then((res) => {
          console.log(res)
          this.refresh()
        })

      this.dialog = false
    },

    refresh() {
      this.fetchCertificate();
    },

    fetchCertificate() {
      this.items = [];

      productApi
        .getTestCertificate()
        .then((response) => {
          const res2 = response.data;
          console.log(res2);
          if (res2.success) {
            const { data } = res2;

            _.each(data, (item) => {
              this.items.push({
                batchNumber: item.batchNumber,
                fileName: item.fileName,
                lastUpdate: item.updatedAt,
              })
            })
          }
        })
        .catch((err) => {
          console.log(err);
          this.snackbarText = err;
          this.snackbar = true;
        });
    },
    viewCertificate(item) {
      if (item.batchNumber) {
        productApi.viewTestCertificate(item.batchNumber)
      } else {
        this.snackbarText = 'Unable to find test certificate file.';
        this.snackbar = true;
      }
    },
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
